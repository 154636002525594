import React, { Component } from 'react'
import "./Career.css"
export class career extends Component {
  render() {
    return (
      <>
       <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
                <div className="swiper-wrapper">

                   
                    <div className="swiper-slide slide-center">

                     
                        <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img"  alt="Banner" />

                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">

                              
                                {/* <!-- <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="index.html" style="color: black">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Career</li>
                                    </ol>
                                </nav> --> */}
                                <h1 className="mb-0 title effect-static-text banner-color" >Career</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section id="slider" className="hero p-0 odd">
            <div className="container">
                <div className="swiper-wrapper ">
                    <div className="swiper-slide slide-center">
                        <div className="slide-content row">
                            <div className="col-12 mx-auto inner">
                                        <h1 className="title effect-static-text mt-5 pt-4" style={{color:"#21333e"}}>Work with us</h1>
                                        <form id="nexgen-simple-form " className="nexgen-simple-form"/>
                                            <input type="hidden" name="section" value="nexgen_form"/>
                                            <input type="hidden" name="reCAPTCHA"/>
                                            <div className="row form-group-margin shadow ">
                                                <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <input type="text" name="name" className="form-control field-name" placeholder="Name"/>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <input type="email" name="email" className="form-control field-email" placeholder="Email"/>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <input type="text" name="phone" className="form-control field-phone" placeholder="Phone"/>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <input type="text" name="phone" className="form-control field-phone" placeholder="Date of Birth"/>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <input type="text" name="phone" className="form-control field-phone" placeholder="Position"/>
                                                </div>  
                                                <div  className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                    <label for="exampleFormControlFile1">Upload Resume</label>
                                                    <input type="file" className="form-control-file" id="exampleFormControlFile1"/>
                                                </div>
                                            
                                                <div className="col-12 m-0 p-2 input-group">
                                                    <textarea name="message" className="form-control field-message" placeholder="Message"></textarea>
                                                </div>
                                                <div className="col-12 col-12 m-0 pl-md-2">
                                                    <span className="form-alert"></span>
                                                </div>
                                                <div className="col-12 input-group m-0 p-2">
                                            
                                                    <a className="btn btn-career">Apply Now</a>
                                                </div>
                                            </div>
                                        {/* </form> */}                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br/>
      </>
    )
  }
}

export default career