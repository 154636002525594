import "./Home.css"
import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import './owlcarousel/dist/assets/owl.carousel.css';
import './owlcarousel/dist/assets/owl.theme.default.css';
import {Link} from "react-router-dom";
const options = {
    margin: 30,
    responsiveClass: true,
    loop:false,
    dots: true,
    autoplay: true,
    // autoplayHoverPause:true,
    autoplayTimeout:5000,
    smartSpeed: 900,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};
export class Home extends Component {
  render() {
    return (
     <>

     <section id="slider" className="hero p-0">
 <div className="swiper-container no-slider animation  slider-h-auto">
   <div className="swiper-wrapper">
    

     {/* <div className="swiper-slide slide-center"> */}
     <div className="d-block d-sm-none overlay responsive1-img "></div>
     <div className=" d-none d-sm-block d-md-none  responsive2-img "></div>
     <div className=" responsive-lg-img d-none d-md-block d-xl-none"></div>
       
       <div className="parallax-y-bg d-none d-xl-block d-xxl-none home-banner overlay "></div>
      
       <div className="slide-content  row ">
         <div className="col-12 d-flex justify-content-start inner pb-lg-4">
           <div className="left text-left">
             <h1  className="title effect-static-text slidfix"> 
             <span className="pre-title m-0 pt-5 d-none d-sm-block " >We are Pushpak Logistics!</span> 
             <span className='pre-title-2' >We offer Multimodal Logistics Solutions across </span>
            

               <div style={{display:"inline-flex"}}>
                 <div style={{color:"#F79431"}}>In</div>
                 <div className='india-title'>di</div>
                 <div style={{color:"#138807"}}>a!</div>
               </div>
              
             </h1>
               <div className="d-sm-inline-flex btn-rep"> <a className="smooth-anchor   btn primary-button btn-2" Target='_blank' href="https://wa.me/9879193900">GET IN TOUCH</a> 
               </div>
               {/* <!-- <a className="btn btn-primary outline-button btn-1  btn-3 mt-3" href="./brochure.html" Target="_blank"> Get Brochure</a> --> */}       
           </div>
         </div>
       </div>
     </div>
   {/* </div> */}
 </div>
</section>
<section id="services"   className="section-1 pt-4 odd offers bg-light ">
    <div   className="container">
    <div   className="row text-center ">
        <div   className="col-12">
          <h2   className="title effect-static-text" style={{color:"#21333e;"}}>We Offer 
          <span   className=" featured bottom" style={{color:"#00337a"}}><span>Services</span></span></h2>
        </div>
      </div>
      <div   className="row">
        <div   className="col-xl-6 col-sm-12 col-sx-12" >
          <div   className="row items">
            <div   className="col-md-6 col-sm-6 col-xl-6 item">
              <div   className="card">
              <i   className="icon fas fa-ship text-white"></i>
                <h5>Coastal Shipping</h5>
                <br/>
                {/* <!-- <p align="justify">Pushpak offers integrated multi-modal logistics solutions involving a combination of land-sea-land and rail modes (road-rail-sea-road route). </p> --> */}
                <Link to="/Services"   className="btn btn-service">
                  READ MORE
                </Link>
              </div>
            </div>
            <div   className="col-md-6  col-sm-6  col-xl-6 item">
            {/* <!-- <div      className=" col-md-6  col-sx-6  col-xl-6 item"> --> */}
              <div   className="card bg-card2">
              <i   className="icon fas fa-shipping-fast text-white"></i>
                <h5>Shipping Agent</h5>
                <br/>
                {/* <!-- <p align="justify">The ship agent is an independent shipping agent whose role often goes unnoticed, but who is of tremendous importance in maritime logistics.</p> --> */}
                <Link to="/Services"   className="btn btn-service">
                  READ MORE
                </Link>

              </div>
            </div>
          </div>

        </div>
        <div   className="col-xl-6  col-sm-12 col-sx-12">
          <div   className="row items">
            <div   className="col-md-6  col-sm-6  col-xl-6 item">
              <div   className="card">
              <i   className="icon fas fa-forward text-white"></i>
              <h5>Clearing &amp; Forwarding</h5>
                {/* <!-- <p align="justify">Pushpak offers integrated multi-modal logistics solutions involving a combination of land-sea-land and rail modes (road-rail-sea-road route). </p> --> */}

                <Link to="/Services"   className="btn btn-service">
                  READ MORE
                </Link>
              </div>
            </div>
            <div   className=" col-md-6   col-sm-6  col-xl-6 item">
              <div   className="card bg-card2">
              <i   className="icon fas fa-truck-loading text-white"></i>
              <h5>Warehousing &amp; Distribution</h5>
                {/* <!-- <p align="justify">The ship agent is an independent shipping agent whose role often goes unnoticed, but who is of tremendous importance in maritime logistics.</p> --> */}
                <Link to="/Services"   className="btn btn-service">
                  READ MORE
                </Link>

              </div>
            </div>
          </div>

        </div>
        

      </div>
    </div>
  </section>
  <section   className="bg-white shadow">
    <div   className="container">
      <div   className="row text-center ">
        <div   className="col-12">
          <h2   className="title effect-static-text">About <span   className="featured bottom"><span>Us</span></span></h2>
        </div>
      </div>
      
        <div   className="card bg-light ">
          <div className="row">
          <div className=" col-xs-12 col-sm-12   d-none d-block   d-sm-block d-md-none">
              <div className="zoom-effect-container">
                <div className="image-card ">
                  <img src="./assets/images/004.jpg"   className="img-responsive rounded img-fluid fluid" alt="about" />
                </div>
              </div>
          </div>
          <div className="col-md-6 col-xs-12 col-lg-7 col-sm-12 ">

            <ul    className="list-about">
              <li   className="text-justify list-1"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p   className="text-justify">A Modi Minerals Group company</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">2+ decades of rich experience in coastal shipping</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Market leader in coastal forwarding handling 2000+ containers per month with major market share</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Own fleet of 105+trucks and multi-axle trailers and 250+ managed fleet</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Believes in one-stop, end-to-end logistics solutions</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify"> Excellent relations with coastal vessel operators and trucking companies</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Ability to move container and bulk cargo with ease across India</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Own offices at all ports in Gujarat and branches in all coastal states</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Operating footprint: Delhi, HP, MP, Punjab, Rajasthan, UP and delivering the cargo of our clients to all Goa, Karnataka, Kerala, Maharashtra, Tamil Nadu, West Bengal, Andhra Pradesh and vice versa.</p>
              </li>

            </ul>
          </div>
          {/* <img src="./assets/images/004.jpg" className="img-demo" /> */}
          <div className=" col-lg-5 col-sm-6 col-xs-12 col-md-6 d-none d-md-block">
              <div className="zoom-effect-container">
                <div className="image-card ">
                  <img src="./assets/images/004.jpg"   className="img-responsive rounded img-fluid fluid" alt="about" />
                </div>
              </div>
          </div>
        </div>
        </div>
    </div>
  </section>
  <section className="bg-white">
    <div className="container">
      <div className="row text-center ">
        <div className="col-12">
          <h2 className="title effect-static-text">About <span className="featured bottom"><span>Modi Minerals Group</span></span></h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 ml-auto col-sm-12 mb-5 order-md-2 ">

       
          <div className="zoom-effect-container  pt-sm-3">
            <div className="image-card">
              <img src="./assets/images/ship.jpeg" className="img-responsive rounded fluid mx-sm-auto" alt="about" />
            </div>
          </div>
        </div>


        <div className="col-md-7 col-sm-12 order-md-1" >
          <div className="card shadow" style={{background:"#f9f9f9"}}>

          <ul    className="list-about">
              <li   className="text-justify list-1"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p   className="text-justify">Established in 1990 with headquarters at Jamnagar, state of Gujarat</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">One of most reputed companies in Saurashtra region</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">One of the major mineral supplier.</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Involved in bauxite and limestone mining operations</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify">Own mines and mineral processing unit</p>
              </li>
              <li   className="text-justify"><i   className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                <p    className="text-justify"> Group companies engaged in various business i.e. - Freight Forwarding (EXIM & Coastal), Logistics, Truck Owning, Tyre Retreading, Ship Owning, Pharma, Trading, Real Estate & Hospitality.</p>
              </li>
             </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="get" className="section-4 why-choose-bg hero p-0 odd">
    <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
      <div className="swiper-wrapper">
 

        <div className="swiper-slide slide-center">
       

          {/* <div className="parallax-y-bg"></div> */}
          <div className="slide-content row">
            <div className="col-12 d-flex justify-content-start justify-content-md-center pb-5 inner">
              <div className="center text-left text-md-center">
          
                <h2 className="title effect-static-text">Why choose <span className="featured us-subtitle bottom"><span>us?</span></span></h2>
                <p className="description mr-auto ml-auto">The advantages of using Pushpak Logistics services</p> 
                <Link to="/Whychooseus" className="why-choose-subtitle" >Why Pushpak?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section style={{color:"black"}} className="bg-light pt-4">
    <div className="container">
      <div className="row text-center intro">
        <div className="col-12">
          <h2>Pushpak<span className="featured bottom pl-2">Awards<span className="pl-3" style={{color:"#164588"}}> </span></span>  </h2>
        </div>
      </div>
 
      <OwlCarousel className="slider-items owl-theme owl-carousel " {...options}>
        <div className="item">
          <div className="card shadow awards-card" >
       
                <img src="assets/images/awards/award-star1.png" className="mx-auto img-fluid" alt="award-star" />
                <h5 >Winner of </h5>
                <div className="award-title  h4">Gujarat Star Awards </div>
                <div  className=" award-year h6">2012 TO 2021</div>
                <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
          </div>
        </div>

        <div className=" item  ">
          <div className="card shadow awards-card"  >
            <img src="assets/images/awards/award-mala2.png" className="mx-auto img-fluid "  alt="award-mala"/>
            <h5 >Winner of </h5>
            <div className="award-title h4">All India Mala Awards </div>
            <div  className="award-year h6">2013 To 16, 19 & 21</div>
            <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
          </div>
        </div>
        <div className=" item ">
          <div className="card shadow awards-card" >
            <img src="assets/images/awards/award-junction3.png" className="mx-auto img-fluid " alt="award-junction3" />
            <h5 >Winner of </h5>
            <div className="award-title  h4">Gujarat Junction Awards </div>
            <div  className="award-year h6">2012 TO 2021</div>
            <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
          </div>
        </div>
        <div className=" item ">
          <div className="card shadow  awards-card" >
            <img src="assets/images/awards/award-achiever4.png" className="mx-auto img-fluid " alt="award-achiever" />
            <h5 >Winner of </h5>
            <div className="award-title  h4">5th International Achievers Summit </div>
            <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
            {/* <!-- <div  className=" h6">2013, 14, 15, 16, 19</div> --> */}
          </div>
        </div>
        <div className=" item">
          <div className="card shadow awards-card" >
            <img src="assets/images/awards/award-seatrade5.png" className="mx-auto img-fluid" alt="awardseatrade"/>
            <br />
            <h5 >Winner of </h5>
            <div className="award-title  h4">India Seatrade Awards</div>
            <div  className="award-year h6">2014 TO 2017</div>
            <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
          </div>
        </div>
        <div className=" item ">
          <div className="card  shadow awards-card" >
            <img src="assets/images/awards/award-maritime.png" className="mx-auto img-fluid " alt="awardmaritime" />
            <br />
            <h5 >Winner of </h5>
            <div className="award-title  h4">India maritime awards</div>
            <div  className="award-year h6">2019</div>
            <div className='content'>
                      <p className="contact-title">DIn publishing and graphic design,           Lorem ipsum is a placeholder text               commonly used to demonstrate the visual         form of a document or a typeface without         relying on meaningful content.</p>
                </div>
          </div>
        </div>
        </OwlCarousel>
      
    </div>
  </section>
     </>
    )
  }
}

export default Home