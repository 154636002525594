import "./Services.css"
import React, { Component } from 'react'
// import ScrollToTop from "react-scroll-to-top";
export class Services extends Component {
  render() {
    return (
      <>
      <section id="slider" className="hero p-0 odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">

                <div className="swiper-slide slide-center">

                    <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img" alt="banner-img"/>

                    <div className="slide-content row text-center">
                        <div className="col-12 mx-auto inner">
                          <h1 className="mb-0 title effect-static-text banner-color">Services</h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
   
    <section id="services" className="section-2 odd offers services-bg">
        <div className="container">
            <div className="row intro">
                <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                    <span className="pre-title m-auto ml-md-0">Our business areas</span>
                    <h2 className="mb-0" style={{color:"#21333e"}}>Excellence in <span className="featured" style={{color:"#193863"}}><span>Services</span></span></h2>
                </div>
                <div className="col-12 col-md-3 align-self-end">
                    <a href="contact.html" className="smooth-anchor btn mx-auto mr-md-0 ml-md-auto outline-button ">GET IN TOUCH</a>
                </div>
            </div>
            <div className="row justify-content-center items">
                <div  className="col-12 col-md-6 item">
                    <div className="card card1">
                        <i className="icon pl-3 fas fa-ship"></i>
                        <h4 className="pl-3">Coastal Shipping</h4>
                        <ul  className="list-about">

                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p>Coastal - EXIM Multimodal Transportation </p>
                            </li>
                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p>Pushpak offers integrated multi-modal logistics solutions involving a combination of land-sea-land and rail modes (road-rail-sea-road route). </p>
                            </li>
                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p>Coastal - Our services are end-to-end offerings from West and North Indian States and fulfilling customer satisfaction to East and South Indian States via vaious major Indian Ports. </p>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                    </div>
                </div>
                <div  className="col-12 col-md-6 item">
                    <div className="bg-card2 card">
                        <i className="icon pl-3 fas fa-user-shield "></i>
                        <h4 className="pl-3">Shipping Agent</h4>
                        <ul  className="list-about">

                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p>The ship agent is an independent shipping agent whose role often goes unnoticed, but who is of tremendous importance in maritime logistics. </p>
                            </li>
                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p >Also known as a shipping agent, the ship agent is an intermediary who acts on behalf of the shipowner. </p>
                            </li>
                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p >At Pushpak we offer shipping agency services in any Indian port, through our extensive network, so that your ocean freight traffic will run smoothly and efficiently. </p>
                            </li>
                            </ul>
                          
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                    </div>
                </div>
               
                <div className="col-12 col-md-6 item">
                    <div className="card bg-card2">
                        <i className="icon pl-3 fas fa-forward"></i>
                        <h4 className="pl-3">Clearing &amp; Forwarding</h4>
                        <ul  className="list-about">

                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p >Forwarding and Clearing services includes Customs assistance and other required documentation such as Tariff Classifications, Audits, Detailed Costing of Shipments, Import or Export Declarations, Certificates of Origin and Delivery Customs Regulations.</p>
                            </li>
                            </ul>
                            {/* <p>&nbsp;</p> */}
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                    </div>
                </div>
                <div  className="col-12 col-md-6 item">
                    <div className="card card1">
                        <i className="icon pl-3 fas fa-truck-loading"></i>
                        <h4 className="pl-3">Warehousing &amp; Distribution</h4>
                        <ul  className="list-about">

                            <li className="text-justify"><i className="fa fa-angle-double-right mr-3" aria-hidden="true"></i>
                                <p >Warehousing and Distribution Services are considered as a supply chain pit-stop where Lean, Efficient and Highly Customisable services are available at all major Indian Ports. </p>
                            </li>                     
                            {/* <li>&nbsp;</li> */}
                            </ul>
                            <p>&nbsp;</p>
                            {/* <div><i className="btn-icon pulse fas fas fa-arrow-right"></i></div> */}
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="services" className="section-2 ">
        <div className="container">
        <h2 className="mb-4 text-center " style={{color:"#21333e"}}> <span className="featured" style={{color:"#193863"}}><span>Our Coastal services offers</span></span></h2>
            <div className="row mx-4">
                <div className="col-sm-12 col-xs-12 col-lg-6 col-xl-6 col-md-6  services-card ">
                    <div className="text-center ">
                        <img src=".\assets\images\graph1.png" className="services-img-endtoend p-4" alt="End to End container Movement"/>    
                          <div className="services-title-2">
                              <h4>End to End container Movement</h4>
                          </div>
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12  col-lg-6 col-xl-6 col-md-6  services-card  ">
                    <div className="text-center align-right">
                         <img src=".\assets\images\pic1.png"  className="img-engagement p-4" alt="Service Engagement"/>
                                <div className="services-title-2" >
                                      <h4>Service Engagement</h4>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className="container map-container">
        <h2 className="mb-4 text-center  " style={{color:"#21333e"}}> <span className="featured" style={{color:"#193863"}}><span>Our Coastal services Map</span></span></h2>
        
        <div className="mx-auto">
            <img className="img-fluid" src=".\assets\images\map.png" alt="map"/>
        </div>
        </div>
    </section>
      </>
    )
  }
}

export default Services