import React, { useState , useEffect } from 'react'
import Axios from 'axios'

function Demo() {
  const [datalist , setdatalist] = useState([])
  useEffect(() => {
    Axios.get('http://localhost:3001/api/data').then((response) => {
      console.log('response:', response.data);
      setdatalist(response.data)
    })
    
  })
  return(
    <>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    {/* <h1> hello</h1> */}
    {
      datalist.map((val)=>{
        return <p> data :{val.id} {val.port} {val.vessel} </p>
      })
    }
    </>
  )
}


export default Demo