import React from 'react'
import Axios from 'axios'
import Loader  from './loader';
import"./Vesselschedule.css"
function Vesselschedule() {

    const [show, setdata] = React.useState([]);
    const [loading, setloading] = React.useState(false)
    const [ visible , setvisible] = React.useState('vessel');
    const [vesselsearch, setvesselsearch] = React.useState(null)
    const [todatesearch, settodatesearch] = React.useState(null)
    const [fromdatesearch, setfromdatesearch] = React.useState("")
    const [toportsearch, settoportsearch] = React.useState("")
    const [fromportsearch, setfromportsearch] = React.useState("")
    const [searchdata, setsearchdata] = React.useState(null)
    // const [portsearchdata, setportdata] = React.useState(null)
    React.useEffect(() => {
      Axios.get('http://192.168.68.21:3001/api/data').then((response) => {
      // console.log('response:', response.data);
      // setdatalist(response.data)
   
      setloading(true);  
        setdata(response.data)
        
      // setdatalist(response.data)
    })
    }, []);

  
    // if(vesselsearch==""){
    //   console.log("Please select a search ")
    //   // searchdata()==null
    
    // }
    const clear= ()=>{
      console.log("clearing...");
      window.location.reload(false);
    }

    
    const searchvessel = () => {
  
      console.log(vesselsearch)
      const vessel = {
        vessel: vesselsearch,
     
      };
        Axios.post(`http://192.168.68.21:3001/api/vesseldata`, vessel).then((response) => {
          // console.log("success", response.data);
          setsearchdata(response.data);
        }).catch((error) => {
          console.log(error);
        });
      
    };
    const portsearch = () => {
      console.log(vesselsearch)
      if(toportsearch!=='' || fromportsearch!=='') {
          const port = {
              toport: toportsearch,
              fromport: fromportsearch
            }
              Axios.post(`http://192.168.68.21:3001/api/portdata`, port).then((response) => {
                // console.log("success", response.data);
                setsearchdata(response.data);
              }).catch((error) => {
                console.log(error);
              });
        }
      
    };
    const datesearch = () => {
      if(todatesearch!=='' && fromdatesearch!=='') {
        const date = {
            todate: todatesearch,
            fromdate: fromdatesearch
          }
          Axios.post(`http://192.168.68.21:3001/api/datedata`, date).then((response) => {
            
            setsearchdata(response.data);
            console.log("success", response.data);
          }).catch((error) => {
            console.log(error);
          });
      }
      
    }
    
  return(
    <>
        <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
                <div className="swiper-wrapper">
                            <div className="swiper-slide slide-center">

                    
                        <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img"  alt="Banner" />

                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">
       
                                <h1 className="mb-0 title effect-static-text banner-color" >Vessel schedule</h1>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
      
              <section className="container-fluid">
            
            <div className="container">
                <div className="card no-hover shadow mt-4 mb-2 bg-white">
                {
                    visible==='vessel' && 
                            <div className="row" id="search-vessel">
                                        <div className="search-box1 m-2 col-lg-6 col-md-9  col-xs-12 col-xl-6 d-flex mx-auto">
                      {/* <i className="fas fa-search"></i> <!-- <button className="btn-search" onclick="ffocus()"><i className="fas fa-search"></i></button> --> */}
                      <div className="col-12  d-flex">
                    
                      <input type="text" id="vessel-id"  onChange={event=>{
                                      setvesselsearch(event.target.value)
                                      }}  value={vesselsearch} className="input-search1" placeholder="Vessel ID"/>
                        <button type="submit" className="btn search_icon" onClick={searchvessel}>
                          <i className="fas fa-search"></i>
                        </button>
                
                      
                      </div>
                    </div>
                 
                    </div>
                    
                }
                {
                    visible==='port' &&
                    <div className="row" id="search-port">
                    <div className="col-12 col-xl-6 col-lg-6 mt-1 col-md-6 col-sm-12 col-sx-12">
                      <div className="mx-auto ">
                        {/* <!-- <label for="fsearch-focus" className=" h2  fw-bold pt-2 pr-3">FROM</label> --> */}
                        <div className="search-box mx-auto">
                          {/* <i className="fas fa-search"></i> <!-- <button className="btn-search" onclick="ffocus()"><i className="fas fa-search"></i></button> --> */}
                          <input type="text" className="input-search "  onChange={event=>{
                              setfromportsearch(event.target.value)
                              }}    placeholder="FROM"/>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6 mt-1  col-sm-12 col-xs-12  ">
                      <div className="mx-auto">
                        <div className="search-box  mx-auto">
                    
                          {/* <!-- <button className="btn-search" onclick="tfocus()"><i className="fas fa-search"></i></button> --> */}
                          <input type="text"  onChange={event=>{
                              settoportsearch(event.target.value)
                              }} className="input-search " placeholder="TO"/>
                        
                        </div>
                      </div>
                    </div>
                          {/* <div className="search-box d-flex mx-auto"> */}
                          <div className="pt-2 mx-auto search-btn">
                              <button className="btn btn-primary btn-search rounded"  onClick={portsearch}> <i className="fas fa-search"></i>SEARCH</button>
                          </div> 
                    </div>
                  // </div>
                }
                {
                    visible==='date' &&
                    <div className="row" id="search-port">
                    <div className="col-12 col-xl-6 col-lg-6  col-md-6 col-sm-12 col-sx-12">
                      <div className=" mx-auto">
                        <div className="search-box mx-auto" >
                          {/* <i className="fas fa-search"></i> <!-- <button className="btn-search" onclick="ffocus()"><i className="fas fa-search"></i></button> --> */}
                        <label for="from-date" className=" h5  text-primary fw-bold pt-2 pr-3">FROM</label> 
                          <input type="date" onChange={event=>{
                              setfromdatesearch(event.target.value)
                              }} id='from-date' className="input-search"  />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6  col-sm-12 col-xs-12  ">
                      <div className="mx-auto ">
                        <div className="search-box  mx-auto ">
                          {/* <!-- <button className="btn-search" onclick="tfocus()"><i className="fas fa-search"></i></button> --> */}
                        <label for="to-date"  className=" h5   text-primary fw-bold pt-2 pr-3">TO</label> 
                          <input type="date"  onChange={event=>{
                              settodatesearch(event.target.value)
                              }}  id='to-date'  className="input-search" />
                        
                        </div>
                      </div>
                    </div>
                    <div className="pt-2 mx-auto search-btn">
                              <button className="btn btn-primary btn-search rounded"  onClick={datesearch}> <i className="fas fa-search"></i>SEARCH</button>
                          </div> 
                  </div>
                }
                <div className=" mx-auto pt-3">
            <div className=" col-12">
              <div className="wrapper pl-5  ">
            
            
                  <input type="radio" name="select" id="option-1" onChange={()=>setvisible('vessel')} value="vessel"  />
                <input type="radio" name="select" id="option-2" onChange={()=>setvisible('port')}  value="port"/>
                <input type="radio" name="select" id="option-3" onChange={()=>setvisible('date')} value="date"/>
                <label for="option-1" className="option option-1">
                  <div className="dot"></div>
                  <span>Vessel</span>
                </label>
                <label for="option-2" className="option option-2">
                  <div className="dot"></div>
                  <span>Port</span>
                </label>
                <label for="option-3" className="option option-3">
                  <div className="dot"></div>
                  <span>Date</span>
                </label>
              </div>

            </div>
          </div>{
            
          (searchdata!==null )?(<>
            <div className="pt-2 mx-auto search-btn">
                              <button className="btn btn-primary btn-search rounded"  onClick={clear}><i class="fa fa-trash" aria-hidden="true"></i>clear filter</button>
                          </div> </>):(<></>)
          }
            </div>
            </div>
           
            {
              loading ? (<>
    {
      
    
      
    }
                                 
    <div className="mask  d-flex align-items-center">
  
  <div className="container">
  {
    
    (searchdata!==null )? (<>{
      Object.keys(searchdata).map((port,i)=>{
        return<>
        
        <div className="justify-content-center ">
                        <div className="card shadow mb-4 mt-2">
                        
                          <h5 className=" p-4 rounded m-0 vtilte" key={i}><i className="fa fa-map-marker-alt pr-2"></i>{port} </h5>
                          <div className="card-body">
                          
                              <div className="table-responsive">
                                      <table className="table table-hover  mb-0">
                                
                                          <thead>
                                              <tr>
                                                  <th scope="col">Vessel</th>
                                                  <th scope="col">POL</th>
                                                  <th scope="col">Exp. Sail Date</th>
                                                  <th scope="col">Exp. Arrival <br/> Date At POD</th>
                                                  <th scope="col">Remarks</th>
                                              </tr>
                                          </thead>
                                          <tbody>   {
                                                  
                                                  searchdata[port].map((item) =>{
                                                   function remarks(){
                                                     if (item.remarks ==='CURRENT VESSEL'){
                                                     return <button className="sbtn pe-none mx-auto btn text-success rounded "><i className="fa fa-location-arrow text-success" aria-hidden="true"></i><span> CURRENT VESSEL </span></button>
                                                 }
                                                     else if (item.remarks ==='GATES CLOSED'){
                                                         return <button className="dbtn pe-none mx-auto  btn text-danger rounded  "><i className="fa fa-times-circle  text-danger" aria-hidden="true"></i><span>GATES CLOSED</span></button>
                                                     }
                                                     else{
                                                         return <p>{item.remarks}</p>
                                                     }
                                               
                                               }
                                                   return <>
                                                               <tr className='table-row'>
                                                               <td scope="row"><i className="fa fa-ship pr-2" aria-hidden="true"></i>{item.vessel}</td>
                                                                   <td><i className="fa fa-map-marker-alt  pr-2"></i> {item.port}</td>
                                                                   <td><i className="fa fa-arrow-up  pr-2" aria-hidden="true"></i>{item.etd}</td>
                                                                   <td><i className="fa fa-arrow-down  pr-2" aria-hidden="true"></i>{item.eta}</td>
                                                                   <td>{remarks()}</td>
                                                               </tr> 
                                                   </>
                                                  })
                                                 
                                               }
                                          
                                          
                                          </tbody>
                                          
                                      </table>
                                </div>
                            </div>
                        </div>
        </div>
        
        </>
        
      })
      
      
    }
                               
         </>):(<> {show ? ( Object.keys(show).map((key, i) => {
      //   filter((port)=>{
      //     if(portsearch==""){
      //               return port;
      //          }
      // else if( port.toLowerCase().includes(portsearch.toLowerCase())){
      //             return port;
      //     }
      //       }).
      // filter((port)=>{
                 
      //   if(port.toLowerCase().includes(portsearch.toLowerCase())){
      //     return port
      //   }
      // })
        return <>
                  <div className="justify-content-center ">
                        <div className="card shadow mb-4 mt-2">
                        
                          <h5 className=" p-4 rounded m-0 vtilte" key={i}><i className="fa fa-map-marker-alt pr-2"></i>{key} </h5>
                          <div className="card-body">
                          
                              <div className="table-responsive">
                                      <table className="table table-hover  mb-0">
                                
                                          <thead>
                                              <tr>
                                                  <th scope="col">Vessel</th>
                                                  <th scope="col">POL</th>
                                                  <th scope="col">Exp. Sail Date</th>
                                                  <th scope="col">Exp. Arrival <br/> Date At POD</th>
                                                  <th scope="col">Remarks</th>
                                              </tr>
                                          </thead>
                                          <tbody key={i}>
                                                {
                                                  
                                                   show[key].map((item) =>{
                                                    function remarks(){
                                                      if (item.remarks ==='CURRENT VESSEL'){
                                                      return <button className="sbtn pe-none mx-auto btn text-success rounded "><i className="fa fa-location-arrow text-success" aria-hidden="true"></i><span> CURRENT VESSEL </span></button>
                                                  }
                                                      else if (item.remarks ==='GATES CLOSED'){
                                                          return <button className="dbtn pe-none mx-auto  btn text-danger rounded  "><i className="fa fa-times-circle  text-danger" aria-hidden="true"></i><span>GATES CLOSED</span></button>
                                                      }
                                                      else{
                                                          return <p>{item.remarks}</p>
                                                      }
                                                
                                                }
                                                    return <>
                                                                <tr className='table-row'>
                                                                    <td scope="row"><i className="fa fa-ship pr-2" aria-hidden="true"></i>{item.vessel}</td>
                                                                    <td><i className="fa fa-map-marker-alt pr-2"></i> {item.port}</td>
                                                                    <td><i className="fa fa-arrow-up pr-2" aria-hidden="true"></i>{item.etd}</td>
                                                                    <td><i className="fa fa-arrow-down pr-2" aria-hidden="true"></i>{item.eta}</td>
                                                                    <td>{remarks()}</td>
                                                                </tr> 
                                                    </>
                                                   })
                                                  
                                                }
                                          
                                          </tbody>
                                          
                                      </table>
                              </div>            
                                      
                          </div>                
                        </div>
                  </div>
        </>
        
      })            

) : (console.log("not fetch")) } </>)
    
  }
  
  
                                
                                      </div>
</div> 
                         
                         </>):(<>
                               
                             
                               <Loader/>
                               </>)
            }
           
          </section>   
 

              {/* <div className="row justify-content-center ">
                    <div className="col-12">
                        <div className="card shadow">
                       
                            <h5 className=" p-4 rounded m-0 vtilte"><i className="fa fa-map-marker-alt pr-2"></i>Demo </h5>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover  mb-0">
                               
                                        <thead>
                                            <tr>
                                                <th scope="col">Vessel</th>
                                                <th scope="col">POL</th>
                                                <th scope="col">Exp. Sail Date</th>
                                                <th scope="col">Exp. Arrival <br/> Date At POD</th>
                                                <th scope="col">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row"><i className="fa fa-ship" aria-hidden="true"></i>SSL GANGA 100</th>
                                                <td><i className="fa fa-map-marker-alt"></i> COCHIN</td>
                                                <td><i className="fa fa-arrow-up" aria-hidden="true"></i>13-05-22</td>
                                                <td><i className="fa fa-arrow-down" aria-hidden="true"></i>21-05-22 </td>
                                                <td> <button className="sbtn pe-none mx-auto btn text-success rounded "><i className="fa fa-location-arrow text-success" aria-hidden="true"></i><span> CURRENT VESSEL </span></button> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="fa fa-ship" aria-hidden="true"></i>SSL GANGA 100</th>
                                                <td><i className="fa fa-map-marker-alt"></i> COCHIN</td>
                                                <td><i className="fa fa-arrow-up" aria-hidden="true"></i>13-05-22</td>
                                                <td><i className="fa fa-arrow-down" aria-hidden="true"></i>21-05-22 </td>
                                                <td> <button className="sbtn pe-none mx-auto btn text-success rounded "><i className="fa fa-location-arrow text-success" aria-hidden="true"></i><span> CURRENT VESSEL </span></button> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="fa fa-ship" aria-hidden="true"></i>SSL GANGA 100</th>
                                                <td><i className="fa fa-map-marker-alt"></i> COCHIN</td>
                                                <td><i className="fa fa-arrow-up" aria-hidden="true"></i>13-05-22</td>
                                                <td><i className="fa fa-arrow-down" aria-hidden="true"></i>21-05-22 </td>
                                                <td> <button className="sbtn pe-none mx-auto btn text-success rounded "><i className="fa fa-location-arrow text-success" aria-hidden="true"></i><span> CURRENT VESSEL </span></button> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="fa fa-ship" aria-hidden="true"></i>SSL GANGA 100</th>
                                                <td><i className="fa fa-map-marker-alt"></i> COCHIN</td>
                                                <td><i className="fa fa-arrow-up" aria-hidden="true"></i>13-05-22</td>
                                                <td><i className="fa fa-arrow-down" aria-hidden="true"></i>21-05-22 </td>
                                                <td> <button className="dbtn pe-none mx-auto  btn text-danger rounded  "><i className="fa fa-times-circle  text-danger" aria-hidden="true"></i><span>GATES CLOSED</span></button> </td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><i className="fa fa-ship" aria-hidden="true"></i>SSL GANGA 100</th>
                                                <td><i className="fa fa-map-marker-alt"></i> COCHIN</td>
                                                <td><i className="fa fa-arrow-up" aria-hidden="true"></i>13-05-22</td>
                                                <td><i className="fa fa-arrow-down" aria-hidden="true"></i>21-05-22 </td>
                                                <td> <button className="dbtn pe-none mx-auto  btn text-danger rounded  "><i className="fa fa-times-circle  text-danger" aria-hidden="true"></i><span>GATES CLOSED</span></button> </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
                          */}

   
    {/* <h1> hello</h1> */}

    </>
  )
}


export default Vesselschedule