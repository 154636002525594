import "./Loader.css";
import React from "react";
export default function Loader() {
  return (
    <>
      <div className="d-flex justify-content-center mt-5 mx-4">
        <div class=" mx-4 spinner-border text-primary" role="status"></div>
        <span class=" h4 classic-4">Loading...</span>
        {/* <span class="h4  d-block visually-hidden">Loading</span> */}
      </div>

      <div class="main-item mx-auto justify-content-center">
      

        <div class="animated-background col-xs-12">
          <div class="background-masker btn-divide-left"></div>
        </div>
{/* 
        <div class="shared-dom">
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
          <div class="sub-rect pure-background"></div>
        </div> */}

        {/* <div class="css-dom"></div> */}
      </div>
    </>
  );
}
